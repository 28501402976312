exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-documents-js": () => import("./../../../src/pages/documents.js" /* webpackChunkName: "component---src-pages-documents-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-premium-js": () => import("./../../../src/pages/premium.js" /* webpackChunkName: "component---src-pages-premium-js" */),
  "component---src-pages-release-notes-js": () => import("./../../../src/pages/release-notes.js" /* webpackChunkName: "component---src-pages-release-notes-js" */),
  "component---src-pages-v-3-archives-js": () => import("./../../../src/pages/v3/archives.js" /* webpackChunkName: "component---src-pages-v-3-archives-js" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-arrows-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/components/arrows/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-arrows-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-autoplay-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/components/autoplay/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-autoplay-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-controller-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/components/controller/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-controller-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-direction-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/components/direction/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-direction-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-drag-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/components/drag/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-drag-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-elements-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/components/elements/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-elements-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-layout-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/components/layout/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-layout-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-move-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/components/move/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-move-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-pagination-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/components/pagination/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-pagination-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-scroll-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/components/scroll/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-scroll-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-slide-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/components/slide/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-slide-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-slides-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/components/slides/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-components-slides-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-extensions-auto-scroll-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/extensions/auto-scroll/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-extensions-auto-scroll-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-extensions-grid-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/extensions/grid/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-extensions-grid-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-extensions-intersection-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/extensions/intersection/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-extensions-intersection-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-extensions-url-hash-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/extensions/url-hash/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-extensions-url-hash-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-extensions-video-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/extensions/video/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-extensions-video-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-accessibility-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/guides/accessibility/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-accessibility-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-apis-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/guides/apis/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-apis-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-arrows-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/guides/arrows/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-arrows-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-auto-width-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/guides/auto-width/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-auto-width-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-autoplay-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/guides/autoplay/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-autoplay-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-events-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/guides/events/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-events-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-extension-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/guides/extension/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-extension-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-getting-started-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/guides/getting-started/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-getting-started-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-i-18-n-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/guides/i18n/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-i-18-n-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-options-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/guides/options/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-options-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-pagination-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/guides/pagination/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-pagination-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-structure-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/guides/structure/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-structure-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-themes-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/guides/themes/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-themes-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-transition-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/guides/transition/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-transition-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-version-3-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/guides/version3/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-guides-version-3-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-integration-react-splide-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/integration/react-splide/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-integration-react-splide-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-integration-svelte-splide-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/integration/svelte-splide/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-integration-svelte-splide-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-integration-vue-splide-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/integration/vue-splide/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-integration-vue-splide-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-tutorials-autoplay-toggle-button-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/tutorials/autoplay-toggle-button/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-tutorials-autoplay-toggle-button-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-tutorials-gallery-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/tutorials/gallery/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-tutorials-gallery-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-tutorials-image-slider-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/tutorials/image-slider/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-tutorials-image-slider-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-tutorials-slider-progress-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/tutorials/slider-progress/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-tutorials-slider-progress-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-tutorials-thumbnail-slider-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v3/tutorials/thumbnail-slider/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-3-tutorials-thumbnail-slider-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-arrows-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/components/arrows/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-arrows-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-autoplay-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/components/autoplay/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-autoplay-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-controller-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/components/controller/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-controller-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-direction-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/components/direction/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-direction-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-drag-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/components/drag/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-drag-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-elements-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/components/elements/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-elements-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-layout-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/components/layout/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-layout-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-move-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/components/move/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-move-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-pagination-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/components/pagination/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-pagination-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-scroll-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/components/scroll/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-scroll-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-slide-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/components/slide/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-slide-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-slides-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/components/slides/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-components-slides-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-extensions-auto-scroll-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/extensions/auto-scroll/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-extensions-auto-scroll-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-extensions-grid-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/extensions/grid/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-extensions-grid-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-extensions-intersection-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/extensions/intersection/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-extensions-intersection-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-extensions-url-hash-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/extensions/url-hash/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-extensions-url-hash-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-extensions-video-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/extensions/video/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-extensions-video-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-accessibility-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/guides/accessibility/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-accessibility-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-apis-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/guides/apis/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-apis-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-arrows-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/guides/arrows/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-arrows-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-auto-width-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/guides/auto-width/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-auto-width-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-autoplay-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/guides/autoplay/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-autoplay-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-events-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/guides/events/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-events-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-extension-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/guides/extension/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-extension-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-getting-started-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/guides/getting-started/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-getting-started-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-i-18-n-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/guides/i18n/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-i-18-n-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-options-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/guides/options/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-options-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-overflow-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/guides/overflow/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-overflow-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-pagination-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/guides/pagination/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-pagination-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-structure-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/guides/structure/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-structure-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-themes-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/guides/themes/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-themes-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-transition-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/guides/transition/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-transition-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-version-4-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/guides/version4/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-guides-version-4-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-integration-react-splide-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/integration/react-splide/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-integration-react-splide-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-integration-svelte-splide-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/integration/svelte-splide/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-integration-svelte-splide-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-integration-vue-splide-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/integration/vue-splide/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-integration-vue-splide-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-tutorials-carousel-progress-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/tutorials/carousel-progress/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-tutorials-carousel-progress-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-tutorials-gallery-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/tutorials/gallery/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-tutorials-gallery-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-tutorials-image-carousel-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/tutorials/image-carousel/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-tutorials-image-carousel-index-mdx" */),
  "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-tutorials-thumbnail-carousel-index-mdx": () => import("./../../../src/templates/document.js?__contentFilePath=/home/runner/work/splide-site/splide-site/docs/v4/tutorials/thumbnail-carousel/index.mdx" /* webpackChunkName: "component---src-templates-document-js-content-file-path-home-runner-work-splide-site-splide-site-docs-v-4-tutorials-thumbnail-carousel-index-mdx" */),
  "component---src-templates-document-list-js": () => import("./../../../src/templates/document-list.js" /* webpackChunkName: "component---src-templates-document-list-js" */)
}

